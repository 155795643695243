.hide__element{
    display: none;
}
.mobile__filter{
    z-index: 30;
    bottom: 27px;
}
.mobile__filter__box{
    height: 54vh; 
    overflow-y: scroll;
}
.filter__btn{
    position: fixed;
    z-index: 20;
    bottom: 36px;
    right: 27px;
}
.sticky__nav{
    position: sticky;
    top: 0px;
}
.page{
    min-height: 45vh;
}
.ql-container{
    min-height: 45vh;
}
.blog__body > h1{
    font-weight: bolder;
    font-size: larger;
    margin-top: 0.9em;
    margin-bottom: 0.9em;
}
.blog__body > h2{
    font-weight: 600!important;
    font-size: x-large !important;
    margin-top: 1.08em;
    margin-bottom: 0.72em;
}
.blog__body > p{
    font-weight: 400 !important;
}
.carousel__img{
    height: 63vh;
}